<template>
    <page-layout class="add-edit-grade-factor-page" :show-loading="true" :loading="loading">
        <page-panel>
            <a-form-model
                ref="ruleForm"
                :model="form"
                :rules="rules"
                :label-col="labelCol"
                :wrapper-col="wrapperCol">
                <a-form-model-item label="规则名称" prop="name">
                    <a-input
                        v-model.trim="form.name"
                        placeholder="请输入规则名称"
                        :disabled="isLook"
                        :max-length="INPUT_MAX_LENGTH" />
                </a-form-model-item>
                <a-form-model-item label="规则描述" prop="description">
                    <a-input
                        v-model.trim="form.description"
                        type="textarea"
                        :rows="4"
                        :disabled="isLook"
                        :max-length="INPUT_MAX_LENGTH_500" />
                </a-form-model-item>
                <a-form-model-item label="风险对象" prop="masterDataId">
                    <risk-obj-select
                        v-model="form.masterDataId"
                        ref="riskObjSelectRef"
                        :disabled="isDisabled"
                        @option="handleOption" />
                </a-form-model-item>
                <a-form-model-item label="风险分类" prop="formCategoryArr">
                    <risk-category-select
                        v-model="form.formCategoryArr"
                        :option="masterOption"
                        :disabled="isDisabled"
                        :category-id="ruleInfo.masterDataClassificationId"
                        @loading="handleLoading"
                        @change="handleCategoryChange" />
                </a-form-model-item>
                <a-form-model-item label="风险因子类型" prop="ruleType">
                    <a-select
                        v-model="form.ruleType"
                        :disabled="isDisabled"
                        placeholder="请选择"
                        @change="handleFactorTypeChange">
                        <a-select-option
                            v-for="item in GRADE_FACTOR_TYPE_ENUM_LIST"
                            :key="item.value"
                            :value="item.value">
                            {{ item.label }}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item label="风险因子" prop="riskFactorDefinitionId">
                    <a-select
                        v-model="form.riskFactorDefinitionId"
                        :disabled="isDisabled"
                        show-search
                        placeholder="请选择风险因子"
                        option-filter-prop="children"
                        @change="handleFactorIdChange">
                        <a-select-option
                            v-for="item in factorDataList"
                            :key="item.id"
                            :filter-option="false"
                            :value="item.id"
                            :label="item.factorName">
                            {{ item.factorName }}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>

                <!--  是否作为计算公式 -->
                <formula-form-item
                    v-model="isFormulaObj"
                    ref="formulaRef"
                    form-key="isFormula"
                    label="计算公式"
                    :is-look="isLook"
                    :factor-data-item="factorDataItem"
                >
                    <a-form-model-item
                        v-if="isFormulaObj.isFormula"
                        label="是否时间计算公式"
                        prop="isDateCalculation"
                        :wrapper-col="wrapperCol"
                        :rules="[{
                            required: true,
                            trigger: 'blur',
                            message: '请选择',
                            validator: validateDate
                        }]">
                        <a-radio-group v-model="form.isDateCalculation" name="radioGroup" :disabled="isLook">
                            <a-radio :value="true">是</a-radio>
                            <a-radio :value="false">否</a-radio>
                        </a-radio-group>
                    </a-form-model-item>
                </formula-form-item>

                <a-form-model-item label="规则配置" :wrapper-col="{span:16}">
                    <component
                        :is="modelShowCom"
                        :ref="childrenRef"
                        :is-look="isLook"
                        :rule-type="form.ruleType"
                        :attributes-data="attributesData"
                        :factor-data-item="factorDataItem"
                        :rule-info="ruleInfo" />
                </a-form-model-item>
                <a-form-model-item :wrapper-col="{ span: 8, offset: 10 }">
                    <a-button type="default" @click="handleCancel">取消</a-button>
                    <a-button
                        v-if="!isLook"
                        style="margin-left: 20px;"
                        type="primary"
                        :loading="submitLoading"
                        @click="handleSubmit">
                        提交
                    </a-button>
                </a-form-model-item>
            </a-form-model>
        </page-panel>
    </page-layout>
</template>

<script>
import {
    FormModel as AFormModel, InputNumber as AInputNumber,
    Button as AButton, Input as AInput, Select as ASelect, Radio as ARadio
} from 'ant-design-vue'
import PageLayout from '@layout/PageLayout'
import {
    GRADE_FACTOR_TYPE_ENUM_LIST,
    GRADE_MODEL_GRADE_FACTOR_BASIC_TYPE as BASIC_TYPE,
    GRADE_MODEL_GRADE_FACTOR_SECTION_TYPE as SECTION_TYPE,
    GRADE_MODEL_GRADE_FACTOR_TEXT_TYPE as TEXT_TYPE,
    RISK_FACTOR_DATA_TYPE_DICT_CASCADE_TYPE,
    RISK_FACTOR_DATA_TYPE_DICT_TYPE
} from '@constant/enum'
import { DEFAULT_PAGES_SIZE } from '@config'
import { ERROR_COMMIT_SUCCESS, ERROR_COMMIT_EDIT } from '@constant/error'
import { INPUT_MAX_LENGTH, INPUT_MAX_LENGTH_500 } from '@constant/Const'

import getCloudRiskFactorListService from '@service/gbCloud/getCloudRiskFactorListService'
import addService from '@service/ruleEngine/baseRule/addRuleService'
import editService from '@service/ruleEngine/baseRule/editRuleService'
import getInfoService from '@service/ruleEngine/baseRule/getRuleInfoService'

import InitForm from '@mixins/initForm'
import { validateWeight } from '@constant/validates'
import { accMul, accDiv } from '@utils/calculate'

export default {
    name: 'AddRiskFactor',
    components: {
        AButton,
        PageLayout,
        AInput,
        AInputNumber,
        ASelect,
        ASelectOption: ASelect.Option,
        ARadio,
        ARadioGroup: ARadio.Group,
        AFormModel,
        AFormModelItem: AFormModel.Item,
        PagePanel: () => import('@components/PagePanel'),
        SelectOptionLabelProp: () => import('@weights/SelectOptionLabelProp'),
        RiskCategorySelect: () => import('@weights/GbCloud/RiskCategorySelect'),
        BaseTypeView: () => import('./BaseTypeView'),
        SectionTypeView: () => import('./SectionTypeView'),
        TextTypeView: () => import('./TextTypeView'),
        FormulaFormItem: () => import('@weights/SetComputedFormulaModal/FormulaFormItem'),
        RiskObjSelect: () => import('@weights/GbCloud/RiskObjSelect')
    },
    mixins: [InitForm],
    data() {
        return {
            accMul,
            accDiv,
            validateWeight,
            INPUT_MAX_LENGTH,
            INPUT_MAX_LENGTH_500,
            BASIC_TYPE,
            SECTION_TYPE,
            labelCol: {
                span: 3
            },
            wrapperCol: {
                span: 10
            },
            GRADE_FACTOR_TYPE_ENUM_LIST, // 基本类型 1 // 数值区间类型 2
            isFormulaObj: {},
            form: {
                name: '', // 基础规则名字
                description: '', // 基础规则描述
                masterDataId: '', // 风险对象
                formCategoryArr: [],
                categoryId: '', // 风险分类
                isFormula: false,
                isDateCalculation: undefined,
                riskFactorDefinitionId: undefined, // 风险因子
                source: '',
                ruleType: BASIC_TYPE// 风险因子类型
            },
            loading: false,
            submitLoading: false,
            masterOption: null, // 风险对象选择的item,
            categoryOption: null, // 风险分类选择的item,
            limit: DEFAULT_PAGES_SIZE,
            total: 0,
            current: 1,
            targetFactorDataList: [], // 因子数据备份 用来过滤dataType
            attributesData: [],
            modelShowCom: '',
            childrenRef: '',
            rules: {
                name: [
                    {
                        required: true,
                        message: '请输入规则名称',
                        trigger: 'blur'
                    }
                ],
                masterDataId: [
                    {
                        required: true,
                        message: '请选择风险对象',
                        trigger: 'blur'
                    }
                ],
                formCategoryArr: [
                    {
                        required: true,
                        message: '请选择风险分类',
                        trigger: 'blur'
                    }
                ],
                riskFactorDefinitionId: [
                    {
                        required: true,
                        message: '请选择风险因子',
                        trigger: 'blur'
                    }
                ],
                // isFormula: [
                //     {
                //         required: true,
                //         message: '是否作为计算公式',
                //         trigger: 'blur'
                //     }
                // ],
                isDateCalculation: [
                    {
                        required: true,
                        message: '请选择',
                        trigger: 'blur'
                    }
                ],
                ruleType: [
                    {
                        required: true,
                        message: '请选择风险因子类型',
                        trigger: 'blur'
                    }
                ]
            },
            visible: false,
            factorDataItem: {}, // 选择的因子对应factorDataList的item->用来获取属性
            ruleInfo: {} // 详情页
            // selectedFormulaObj: null// 设置的公式
        }
    },
    computed: {
        // id
        id() {
            return this.$route.query.id || ''
        },
        // 查看
        isLook() {
            const look = this.$route.query.look
            return look && !!+look
        },
        // 修改时部分表单不可修改/查看
        isDisabled() {
            const { isLook } = this
            const { edit, id } = this.$route.query
            return isLook || id && edit && !!+edit
        },
        params() {
            // eslint-disable-next-line no-unused-vars
            const { formCategoryArr, ...params } = this.form
            const { isFormulaObj } = this
            return {
                ...params,
                ...isFormulaObj
            }
        },
        isBaseType() {
            const { ruleType } = this.form
            return ruleType === BASIC_TYPE
        },
        isSectionType() {
            const { ruleType } = this.form
            return ruleType === SECTION_TYPE
        },
        isTextType() {
            const { ruleType } = this.form
            return ruleType === TEXT_TYPE
        },
        factorDataList() {
            const { targetFactorDataList, isBaseType } = this
            // const { ruleType } = this.form
            const res = targetFactorDataList.filter(item => {
                const dataType = item.dataType
                // 基本类型 需要dataType是'DICT' 'DICT_CASCADE'
                if (isBaseType) {
                    return dataType === RISK_FACTOR_DATA_TYPE_DICT_TYPE || dataType === RISK_FACTOR_DATA_TYPE_DICT_CASCADE_TYPE
                } else {
                    return targetFactorDataList
                }
            })
            return res
        },
        serviceFn() {
            const { id } = this
            return id ? editService : addService
        },
        commitMsg() {
            const { id } = this
            return id ? ERROR_COMMIT_EDIT : ERROR_COMMIT_SUCCESS
        }
        // // 公式的name
        // comment() {
        //     const { selectedFormulaObj } = this
        //     return selectedFormulaObj && selectedFormulaObj.formulaDisplay
        // }
    },
    watch: {
        masterOption(val) {
            this.form.source = val.source
        },
        'form.categoryId': {
            handler(val) {
                this.targetFactorDataList = []
                this.form.masterDataClassificationId = val
                val && this.getFactorList()
            }
        },
        // 风险因子类型动态切换视图
        'form.ruleType': {
            handler() {
                const { isBaseType, isSectionType, isTextType } = this
                if (isBaseType) {
                    this.modelShowCom = 'BaseTypeView' // 基本类型
                    this.childrenRef = 'basicRef'
                } else if (isSectionType) {
                    this.modelShowCom = 'SectionTypeView' // 数值区间类型
                    this.childrenRef = 'sectionRef'
                } else if (isTextType) { // 文本类型
                    this.modelShowCom = 'TextTypeView'
                    this.childrenRef = 'textRef'
                }
            },
            immediate: true
        },
        // 选择了因子->去取属性值列表
        'form.riskFactorDefinitionId': {
            handler(val) {
                if (!val) return
                this.factorDataItem = {
                    ...this.factorDataList.find(item => item.id === val)
                }
            },
            immediate: true
        },
        factorDataList: {
            handler(val) {
                const { id, form } = this
                if (id && form.riskFactorDefinitionId) { // 回显修改的属性值信息
                    this.factorDataItem = val.find(item => item.id === form.riskFactorDefinitionId)
                }
            }
        },
        // 修改 获取详情数据
        id: {
            handler(val) {
                if (!val) return
                this.form.id = val
                this.sendInfoService()
            },
            immediate: true
        },
        ruleInfo: {
            handler(val) {
                const { ruleInfo, form } = this
                this.initForm(ruleInfo, form)
                this.form.categoryId = ruleInfo.masterDataClassificationId
                const { masterDataId, source } = val
                this.masterOption = {
                    masterDataId,
                    source
                }
                const { formulaDescription, formulaDisplay, isFormula } = ruleInfo
                if (isFormula) {
                    // this.selectedFormulaObj =
                    this.isFormulaObj = {
                        isFormula,
                        formulaDescription,
                        formulaDisplay
                    }
                }
            }
        }
    },
    methods: {
        // 校验 是否时间
        validateDate(rule, value, callback) {
            if (this.form.isDateCalculation !== undefined) {
                callback()
            } else {
                callback(new Error('请选择'))
            }
        },
        handleLoading(val) {
            this.loading = val
        },
        // 选择的风险对象item
        handleOption(option) {
            this.masterOption = option
            this.form.categoryId = '' // 点击后再清空form关联数据
        },
        handleFactorTypeChange() {
            // 风险因子类型不同 选择的风险因子也不同
            this.form.riskFactorDefinitionId = ''
        },
        handleCategoryOption(option) {
            this.categoryOption = option
        },
        handleCategoryChange(val) {
            this.form.categoryId = val
            this.form.riskFactorDefinitionId = ''
        },
        handleFactorIdChange(val) {
            if (!val) return
            this.factorDataItem = this.factorDataList.find(item => item.id === val)
        },
        handleCancel() {
            this.$router.back()
        },
        formulaValidate() {
            return this.$refs.formulaRef.handleFormValidate()
            // && this.refs.formulaRef.handleFormValidate()
        },
        handleSubmit() {
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    const { childrenRef } = this
                    // 子组件的数组
                    const resObj = this.$refs[childrenRef].handleFormValidate()
                    if (!resObj) return

                    const formulaValidate = this.formulaValidate()
                    if (!formulaValidate) return

                    this.sendCommitService(resObj)
                }
            })
        },
        async sendCommitService(resObj) {
            try {
                this.submitLoading = true
                const { params, commitMsg, serviceFn } = this
                let paramsObj = {
                    ...params,
                    ...resObj
                }
                await serviceFn(paramsObj)
                this.$message.success(commitMsg)
                this.$router.back()
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.submitLoading = false
            }
        },
        /**
         * 获取风险因子
         * */
        async getFactorList() {
            try {
                const { params } = this
                const { masterDataId, categoryId, source } = params
                const res = await getCloudRiskFactorListService({
                    masterDataId,
                    categoryId,
                    source
                })
                this.targetFactorDataList = res// id ? res : res.filter(item => item.isUsed === false)
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            }
        },
        // 因子详情数据
        async sendInfoService() {
            try {
                this.loading = true
                const { id } = this
                this.ruleInfo = await getInfoService({
                    id
                })
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang='scss'>
.add-edit-grade-factor-page {
    .ant-form-item-label {
        min-width: 130px;
    }

    &__weight-input {
        width: 150px !important;
        margin-right: 8px
    }

    &__set-formula-text {
        float: right;
        color: #2998ff;
        font-size: 14px;
        cursor: pointer;
    }
}
</style>
