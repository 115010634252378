<!-- 不作为限制显示面板 -->
<template>
    <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol">
        <a-form-model-item label="风险对象" prop="masterDataId">
            <risk-obj-select
                v-model="form.masterDataId"
                @option="handleOption" />
        </a-form-model-item>
        <a-form-model-item label="风险分类" prop="formCategoryArr">
            <risk-category-select
                v-model="form.formCategoryArr"
                ref="categoryId"
                :option="masterOption"
                @change="handleCategoryChange" />
        </a-form-model-item>
        <a-form-model-item label="风险因子" prop="riskFactorDefinitionIds">
            <select-option-label-prop
                v-model="form.riskFactorDefinitionIds"
                ref="selectOption"
                option-label="factorName"
                option-value="id"
                :filter-option="false"
                :select-options-data="factorDataList"
                placeholder="请选择风险因子" />
        </a-form-model-item>
    </a-form-model>
</template>

<script>
import {
    FormModel as AFormModel
} from 'ant-design-vue'
import RiskObjSelect from '@weights/GbCloud/RiskObjSelect'
import RiskCategorySelect from '@weights/GbCloud/RiskCategorySelect'
import SelectOptionLabelProp from '@weights/SelectOptionLabelProp'
import getCanAddRiskFactorService from '@service/ruleEngine/DataCheckSetting/getCanAddRiskFactorService'
export default {
    name: 'NoLimitShowPanel',
    components: {
        AFormModel,
        AFormModelItem: AFormModel.Item,
        RiskObjSelect,
        RiskCategorySelect,
        SelectOptionLabelProp
    },
    data() {
        return {
            labelCol: {
                span: 4,
                offset: 4
            },
            wrapperCol: {
                span: 8
            },
            masterOption: null, // 风险对象选择的item,
            factorDataList: [], // 风险因子列表
            searchName: '',
            form: {
                masterDataId: '',
                categoryId: '',
                formCategoryArr: [],
                riskFactorDefinitionIds: [],
                source: '',
                dataVerificationModelId: ''// 数据核验模型ID
            },
            rules: {
                masterDataId: [
                    {
                        required: true,
                        message: '请选择风险对象',
                        trigger: 'blur'
                    }
                ],
                formCategoryArr: [
                    {
                        required: true,
                        message: '请选择风险分类',
                        trigger: 'blur'
                    }
                ],
                riskFactorDefinitionIds: [
                    {
                        required: true,
                        message: '请选择风险因子',
                        trigger: 'blur'
                    }
                ]
            }
        }
    },
    computed: {
        queryId() {
            return this.$route.query.queryId
        }
    },
    watch: {
        masterOption(val) {
            this.form.categoryId = ''
            this.form.source = val.source
            this.form.riskFactorDefinitionIds = []// 清空下级关联内容
            this.factorDataList = []
        },
        'form.categoryId': {
            handler(val) {
                this.form.riskFactorDefinitionIds = []// 清空下级关联内容
                this.factorDataList = []
                if (val) {
                    this.getCanAddRiskFactor()
                }
            }
        }
    },
    created() {
        this.form.dataVerificationModelId = this.queryId
    },
    methods: {
        handleOption(option) {
            this.masterOption = option
        },
        handleCategoryChange(val) {
            this.form.categoryId = val
        },
        resetFields() {
            this.$refs.ruleForm.resetFields()
        },
        handleFormValidate() {
            let res = null
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    // eslint-disable-next-line no-unused-vars
                    const { formCategoryArr, ...params } = this.form
                    const { masterDataId, categoryId, source, riskFactorDefinitionIds } = params
                    params.dataVerificationFactorDTOs = [
                        {
                            masterDataId,
                            categoryId,
                            source,
                            riskFactorDefinitionIds
                        }
                    ]
                    res = params
                }
            })
            return res
        },
        // 通过风险分类获取风险因子
        async getCanAddRiskFactor() {
            try {
                const { form } = this
                const params = JSON.parse(JSON.stringify(form))
                delete params.formCategoryArr
                const res = await getCanAddRiskFactorService(params)
                this.factorDataList = res.filter(item => item.isUsed === false)
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            }
        }
    }
}
</script>

<style lang='scss'>

</style>
