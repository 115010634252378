<template>
    <div>
        包含 <br>
        <a-input
            v-model="form.containKeywords"
            type="textarea"
            placeholder="请使用空格分隔每个关键字"
            :disabled="isLook"
            :rows="3"
            :max-length="INPUT_MAX_LENGTH_500" />
        不包含 <br>
        <a-input
            v-model="form.notContainKeywords"
            type="textarea"
            placeholder="请使用空格分隔每个关键字"
            :rows="3"
            :disabled="isLook"
            :max-length="INPUT_MAX_LENGTH_500" />
    </div>
</template>

<script>
import { Input as AInput } from 'ant-design-vue'

import { INPUT_MAX_LENGTH_500 } from '@constant/Const'
import { GRADE_MODEL_GRADE_FACTOR_TEXT_TYPE as TEXT_TYPE } from '@constant/enum'

function format(str) {
    return str && str.replace(/\s+/g, '|')
}

function format2(str) {
    return str && str.replace(/\|/g, ' ')
}

export default {
    name: 'TextTypeView',
    components: {
        AInput
    },
    props: {
        ruleInfo: {
            type: Object,
            default() {
                return {}
            }
        },
        isLook: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            form: {},
            INPUT_MAX_LENGTH_500
        }
    },
    watch: {
        ruleInfo: {
            handler(val) {
                if (val.ruleType === TEXT_TYPE) {
                    const { containKeywords, notContainKeywords } = val.basicRuleDetailList[0]
                    this.form.containKeywords = format2(containKeywords)
                    this.form.notContainKeywords = format2(notContainKeywords)
                }
            },
            immediate: true
        }
    },
    methods: {
        handleFormValidate() {
            const { containKeywords, notContainKeywords } = this.form
            if (!containKeywords && !notContainKeywords) {
                this.$message.warn('请至少填写一种包含或不包含的情况')
                return false
            }
            return {
                basicRuleDetailList: [
                    {
                        containKeywords: format(containKeywords),
                        notContainKeywords: format(notContainKeywords),
                        ruleType: TEXT_TYPE
                    }
                ]
            }
        }
    }
}
</script>

<style lang='scss'>

</style>
