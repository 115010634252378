<template>
    <list-page-layout>
        <search-panel slot="header" @search="handleSearch" @refresh="handleSearch" />
        <container-panel>
            <template slot="title">
                基础规则
            </template>
            <a-button slot="button"
                      v-auth="RULE_ENGINE_ADD_BASE_RULE_BUTTON"
                      type="primary"
                      @click="handleAdd">
                添加基础规则
            </a-button>
            <template slot="content" slot-scope="">
                <a-table :columns="columns"
                         :data-source="dataList"
                         :pagination="pagination"
                         :loading="loading"
                         :scroll="scrollConfig"
                         row-key="id"
                         @change="handleTableChange">
                    <template slot="state" slot-scope="text,record">
                        <a-switch
                            :checked="Boolean(record.state)"
                            @change="value => handleSwitch(value, record)" />
                    </template>

                    <template slot="action" slot-scope="action">
                        <a-button
                            v-auth="RULE_ENGINE_LOOK_BASE_RULE_BUTTON"
                            type="link"
                            @click="handleLook(action)">
                            查看
                        </a-button>
                        <a-button
                            v-auth="RULE_ENGINE_COPY_BASE_RULE_BUTTON"
                            type="link"
                            @click="handleCopy(action)">
                            复制
                        </a-button>
                        <a-button
                            v-auth="RULE_ENGINE_EDIT_BASE_RULE_BUTTON"
                            :disabled="isUsedFn(action.state)"
                            type="link"
                            @click="handleEdit(action)">
                            编辑
                        </a-button>
                        <a-button
                            v-auth="RULE_ENGINE_DELETE_BASE_RULE_BUTTON"
                            :disabled="isUsedFn(action.state)"
                            type="link"
                            @click="handleDelete(action)">
                            删除
                        </a-button>
                    </template>
                </a-table>
            </template>
        </container-panel>
    </list-page-layout>
</template>

<script>
import { Button as AButton, Table as ATable, Switch as ASwitch } from 'ant-design-vue'
import ListPageLayout from '@layout/ListPageLayout'
import SearchPanel from './SearchPanel'
import ContainerPanel from '@components/ContainerPanel'

import { DEFAULT_PAGES_SIZE } from '@config'
import {
    RULE_ENGINE_ADD_BASE_RULE_BUTTON,
    RULE_ENGINE_DELETE_BASE_RULE_BUTTON,
    RULE_ENGINE_EDIT_BASE_RULE_BUTTON,
    RULE_ENGINE_LOOK_BASE_RULE_BUTTON,
    RULE_ENGINE_COPY_BASE_RULE_BUTTON
} from '@constant/authEnum/ruleEngine'
import { RULE_ENGINE_DATA_CHECK_INFO_MENU_CODE } from '@constant/menu_code'
import {
    DATA_CHECK_MODEL_AUDIT_STATE_PASS,
    isUsedFn
} from '@constant/enum'

import tableShowTotal from '@mixins/tableShowTotal'
import { filterParams } from '@utils/search'
import enumOperate from '@mixins/enumOperate'
import { open } from '@utils'

import getListService from '@service/ruleEngine/baseRule/getRuleListService'
import switchService from '@service/ruleEngine/baseRule/switchRuleService'
import copyService from '@service/ruleEngine/baseRule/copyRuleService'
import deleteService from '@service/ruleEngine/baseRule/deleteRuleService'
import { ERROR_COMMIT_CLOSE, ERROR_COMMIT_OPEN, ERROR_COPY_SUCCESS, ERROR_COMMIT_DELETE } from '@constant/error'
import { scrollConfig } from '@constant/Const'
import { actionModelConfirm } from '@utils/tip'

const columns = [
    {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        width: 100,
        customRender: (v, r, i) => ++i
    },
    {
        title: '规则名称',
        dataIndex: 'name',
        width: 200,
        key: 'name',
        ellipsis: true
    },
    {
        title: '规则描述',
        dataIndex: 'description',
        width: 200,
        key: 'description',
        ellipsis: true,
        customRender: v => v ? v : '/'
    },
    {
        title: '更新时间',
        dataIndex: 'createDateTime',
        width: 200,
        key: 'createDateTime'
    },
    {
        title: '创建人',
        dataIndex: 'createName',
        width: 100,
        key: 'createName'
    },
    {
        title: '状态',
        dataIndex: 'state',
        key: 'state',
        width: 120,
        scopedSlots: {
            customRender: 'state'
        }
    },
    {
        title: '操作',
        key: 'action',
        width: 200,
        scopedSlots: {
            customRender: 'action'
        }
    }
]
export default {
    name: 'DataCheckList',
    components: {
        SearchPanel,
        ListPageLayout,
        ContainerPanel,
        ATable,
        AButton,
        ASwitch
    },
    mixins: [tableShowTotal, enumOperate],
    data() {
        return {
            RULE_ENGINE_ADD_BASE_RULE_BUTTON,
            RULE_ENGINE_DELETE_BASE_RULE_BUTTON,
            RULE_ENGINE_EDIT_BASE_RULE_BUTTON,
            RULE_ENGINE_LOOK_BASE_RULE_BUTTON,
            RULE_ENGINE_COPY_BASE_RULE_BUTTON,
            RULE_ENGINE_DATA_CHECK_INFO_MENU_CODE,
            DATA_CHECK_MODEL_AUDIT_STATE_PASS,
            columns,
            params: {},
            dataList: [],
            limit: DEFAULT_PAGES_SIZE,
            total: 0,
            current: 1,
            loading: false,
            visible: false,
            scrollConfig
        }
    },
    computed: {
        pagination() {
            const { total, limit, current, tableShowTotal } = this
            return {
                showTotal: (total, range) => tableShowTotal(total, range),
                current,
                total,
                pageSize: limit,
                showSizeChanger: true,
                showQuickJumper: true
            }
        }
    },
    created() {
        this.getList()
    },
    methods: {
        isUsedFn,
        handleSearch(params) {
            this.params = params
            this.getList()
        },
        handleCancel() {
            this.visible = false
        },
        handleSuccess() {
            this.visible = false
            this.getList()
        },
        handleAdd() {
            this.routerPush()
        },
        handleLook({ id }) {
            open.call(this, 'AddOrEditBaseRule', {
                id,
                look: 1
            })
        },
        handleEdit({ id }) {
            this.routerPush({
                id,
                edit: 1
            })
        },
        routerPush(query = {}) {
            this.$router.push({
                name: 'AddOrEditBaseRule',
                query
            })
        },
        async handleCopy({ id }) {
            await actionModelConfirm.call(this, '确认复制该规则吗？')
            this.sendCopyService(id)
        },
        async handleDelete({ id }) {
            await actionModelConfirm.call(this, '确认删除该规则吗？', 'danger')
            try {
                await deleteService(id)
                this.getList()
                this.$message.success(ERROR_COMMIT_DELETE)
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            }
        },
        async sendCopyService(id) {
            try {
                await copyService({
                    id
                })
                this.getList()
                this.$message.success(ERROR_COPY_SUCCESS)
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            }
        },
        async handleSwitch(value, record) {
            try {
                const params = {
                    enable: +value,
                    id: record.id
                }
                await switchService(params)
                let message = value ? ERROR_COMMIT_OPEN : ERROR_COMMIT_CLOSE
                this.$message.success(message)
                this.getList()
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            }
        },
        handleTableChange(pagination) {
            const { current, pageSize } = pagination
            this.current = current
            this.limit = pageSize
            this.getList()
        },
        async getList() {
            try {
                this.loading = true
                const { current, limit, params } = this
                const param = {
                    current,
                    size: limit,
                    ...params
                }
                const result = await getListService(filterParams(param))
                this.dataList = result.records
                this.current = result.current
                this.total = result.total
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }

        }
    }
}
</script>

<style lang='scss'>

</style>
