<!-- 评分因子类型-基本类型 -->
<template>
    <a-form-model
        ref="ruleForm"
        :model="form"
        class="grade-factor-basic-type-form">
        <table>
            <thead class="grade-factor-basic-type-form__thead">
                <tr>
                    <th
                        v-for="item in basicTypeTab"
                        :key="item.name"
                        :style="{width:item.width+'px',textAlign:'left',padding:'0 20px'}"
                        class="ant-table-align-center">
                        {{ item.name }}
                    </th>
                </tr>
            </thead>
            <tbody class="grade-factor-basic-type-form__tbody">
                <tr v-for="(item,index) in attributesData" :key="item.value">
                    <td>
                        <a-form-model-item :disabled="isLook">{{ index + 1 }}</a-form-model-item>
                    </td>
                    <td>
                        <a-form-model-item :disabled="isLook">{{ item.name }}</a-form-model-item>
                    </td>
                    <td>
                        <a-form-model-item>
                            <a-checkbox v-model="item.checked" :disabled="isLook" />
                        </a-form-model-item>
                    </td>
                </tr>
            </tbody>
        </table>
    </a-form-model>
</template>

<script>
import { Checkbox as ACheckbox, FormModel as AFormModel } from 'ant-design-vue'

import {
    GRADE_MODEL_GRADE_FACTOR_BASIC_TYPE,
    RISK_FACTOR_DATA_TYPE_DICT_CASCADE_TYPE,
    RISK_FACTOR_DATA_TYPE_DICT_TYPE
} from '@constant/enum'
import { validateAttribute } from '@constant/validates'
import getAttributesService from '@service/gbCloud/getAttributesService'

export default {
    name: 'BaseTypeView',
    components: {
        ACheckbox,
        AFormModel,
        AFormModelItem: AFormModel.Item
    },
    props: {
        // 详情数据 修改使用
        ruleInfo: {
            type: Object,
            default() {
                return {}
            }
        },
        // 选择的因子获取数据
        factorDataItem: {
            type: Object,
            default() {
                return {}
            }
        },
        ruleType: {
            type: Number
        },
        isLook: {
            type: Boolean
        }
    },
    data() {
        return {
            form: {
                basicRuleDetailList: []
            },
            score: '',
            basicTypeTab: [
                {
                    name: '序号',
                    width: 80,
                    align: 'center'
                }, {
                    name: '属性',
                    width: 150,
                    align: 'center'
                }, {
                    name: '是否选择',
                    width: 180,
                    align: 'center'
                }
            ],
            attributesData: [], // 属性值列表
            loading: false,
            validateAttribute
        }
    },
    computed: {
        params() {
            const { ruleType, attributesData } = this
            const basicRuleDetailList = []
            attributesData.forEach(item => {
                if (item.checked) {
                    basicRuleDetailList.push({
                        value: item.value,
                        ruleType
                    })
                }
            })
            return {
                basicRuleDetailList
            }
        },
        attributesDataMap() {
            const { attributesData } = this
            let map = new Map()
            attributesData.forEach(item => {
                map.set(item.value, item)
            })
            return map
        }
    },
    watch: {
        factorDataItem: {
            handler() {
                this.getAttributes()
            },
            immediate: true
        },
        attributesData: {
            handler(val) {
                if (!val.length) return
                const { ruleInfo, attributesDataMap } = this
                const { basicRuleDetailList } = ruleInfo
                if (ruleInfo.ruleType === GRADE_MODEL_GRADE_FACTOR_BASIC_TYPE) {
                    (basicRuleDetailList || []).forEach(item => { // 回显
                        this.$set(attributesDataMap.get(item.value), 'checked', true)
                    })
                }
            },
            immediate: true
        }
    },
    methods: {
        handleFormValidate() {
            let res = null
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    res = this.params
                }
            })
            return res
        },
        resetFields() {
            this.form.basicRuleDetailList = []
        },
        /**
         * 获取属性值
         * */
        async getAttributes() {
            const { factorDataItem } = this
            const { dataType, dictionaryCategoryId, source } = factorDataItem
            // 指定dataType 才有属性值
            if (dataType !== RISK_FACTOR_DATA_TYPE_DICT_TYPE && dataType !== RISK_FACTOR_DATA_TYPE_DICT_CASCADE_TYPE) return
            try {
                this.loading = true
                this.attributesData = await getAttributesService({
                    dataType,
                    dictionaryCategoryId,
                    source
                })
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang='scss'>
@import '@assets/styles/varibles.scss';

.grade-factor-basic-type-form {
    &__tbody {
        td {
            text-align: left;
            padding: 0 20px;
        }
    }

    &__thead {
        background-color: $custom-table-title-background-color;

        tr {
            line-height: $custom-table-thead-height
        }
    }
}

</style>
