<template>
    <page-layout class="add-edit-grade-factor-page" :show-loading="true" :loading="loading">
        <page-panel>
            <a-form-model
                ref="ruleForm"
                :model="form"
                :rules="rules"
                :label-col="labelCol"
                :wrapper-col="wrapperCol">
                <a-form-model-item label="风险对象" prop="masterDataId">
                    <risk-obj-select
                        v-model="form.masterDataId"
                        ref="riskObjSelectRef"
                        :disabled="isDisabled"
                        @option="handleOption" />
                </a-form-model-item>
                <a-form-model-item label="风险分类" prop="formCategoryArr">
                    <risk-category-select
                        v-model="form.formCategoryArr"
                        :option="masterOption"
                        :disabled="isDisabled"
                        :category-id="factorRecordInfo.masterDataClassificationId"
                        @loading="handleLoading"
                        @change="handleCategoryChange" />
                </a-form-model-item>
                <a-form-model-item label="评分因子类型" prop="scoringFactorType">
                    <a-select
                        v-model="form.scoringFactorType"
                        :disabled="isDisabled"
                        placeholder="请选择"
                        @change="handleFactorTypeChange">
                        <a-select-option
                            v-for="item in GRADE_FACTOR_TYPE_ENUM_LIST2"
                            :key="item.value"
                            :value="item.value">
                            {{ item.label }}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item label="评分因子" prop="riskFactorDefinitionId">
                    <a-select
                        v-model="form.riskFactorDefinitionId"
                        :disabled="isDisabled"
                        show-search
                        placeholder="请选择评分因子"
                        option-filter-prop="children"
                        @change="handleFactorIdChange">
                        <a-select-option
                            v-for="item in factorDataList"
                            :key="item.id"
                            :filter-option="false"
                            :value="item.id"
                            :label="item.factorName">
                            {{ item.factorName }}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item
                    v-if="isWeight"
                    label="权重"
                    prop="weight"
                    :rules="[{
                        required: isWeight?true:false,
                        message: '请输入权重',
                        trigger: 'blur',
                    },{validator:validateWeight}]">
                    <a-input
                        v-model="form.weight"
                        :max="100"
                        :min="0"
                        placeholder="请输入权重百分比"
                        suffix="%"
                    />
                </a-form-model-item>


                <a-form-model-item label="是否作为计算公式" prop="isFormula">
                    <a-radio-group v-model="form.isFormula" name="radioGroup">
                        <a-radio :value="true">是</a-radio>
                        <a-radio :value="false">否</a-radio>
                    </a-radio-group>
                    <div v-show="form.isFormula"
                         class="add-edit-grade-factor-page__set-formula-text"
                         @click="handleSetFormula">
                        设置计算公式
                    </div>
                </a-form-model-item>
                <a-form-model-item
                    v-show="form.isFormula"
                    :wrapper-col="{span: 10,offset: 3}">
                    <a-input v-model="comment" type="textarea" :disabled="true" />
                </a-form-model-item>


                <a-form-model-item label="各属性分值" :wrapper-col="{span:16}">
                    <!--          <grade-factor-basic-type
                                  :attributesData="attributesData"
                                  v-show="form.scoringFactorType === GRADE_MODEL_GRADE_FACTOR_BASIC_TYPE"/>
                              <grade-factor-section-type
                                  :attributesData="attributesData"
                                  v-show="form.scoringFactorType === GRADE_MODEL_GRADE_FACTOR_SECTION_TYPE"/> -->
                    <component
                        :is="modelShowCom"
                        :ref="childrenRef"
                        :scoring-factor-type="form.scoringFactorType"
                        :attributes-data="attributesData"
                        :factor-data-item="factorDataItem"
                        :factor-record-info="factorRecordInfo" />
                </a-form-model-item>
                <a-form-model-item :wrapper-col="{ span: 8, offset: 10 }">
                    <a-button type="default" @click="handleCancel">取消</a-button>
                    <a-button type="primary" :loading="submitLoading" style="margin-left: 20px;" @click="handleSubmit">
                        提交
                    </a-button>
                </a-form-model-item>
            </a-form-model>
        </page-panel>

        <set-computed-formula-modal
            ref="formulaRef"
            :factor-data-item="factorDataItem"
            :visible="visible"
            :selected-formula-obj="selectedFormulaObj"
            @cancel="handleModalCancel"
            @success="handleSuccess" />
    </page-layout>
</template>

<script>
import {
    FormModel as AFormModel, InputNumber as AInputNumber,
    Button as AButton, Input as AInput, Select as ASelect, Radio as ARadio
} from 'ant-design-vue'
import PageLayout from '@layout/PageLayout'

import {
    GRADE_FACTOR_TYPE_ENUM_LIST2, GRADE_MODEL_GRADE_FACTOR_BASIC_TYPE,
    GRADE_MODEL_GRADE_FACTOR_SECTION_TYPE, RISK_FACTOR_DATA_TYPE_DICT_CASCADE_TYPE, RISK_FACTOR_DATA_TYPE_DICT_TYPE
} from '@constant/enum'

import getCanAddRiskGradeFactorService from '@service/ruleEngine/GradeModel/getCanAddRiskGradeFactorService'
import addGradeFactorService from '@service/ruleEngine/GradeModel/addGradeFactorService'
import editGradeFactorService from '@service/ruleEngine/GradeModel/editGradeFactorService'
import getGradeFactorInfoService from '@service/ruleEngine/GradeModel/getGradeFactorInfoService'
import { DEFAULT_PAGES_SIZE } from '@config'
import { ERROR_COMMIT_SUCCESS, ERROR_COMMIT_EDIT } from '@constant/error'
import getAttributesService from '@service/gbCloud/getAttributesService'
import InitForm from '@mixins/initForm'
import { validateWeight } from '@constant/validates'
import { accMul, accDiv } from '@utils/calculate'

export default {
    name: 'AddRiskFactor',
    components: {
        ASelect,
        ASelectOption: ASelect.Option,
        AInput,
        AInputNumber,
        ARadioGroup: ARadio.Group,
        ARadio,
        PageLayout,
        SelectOptionLabelProp: () => import('@weights/SelectOptionLabelProp'),
        AFormModel,
        AFormModelItem: AFormModel.Item,
        PagePanel: () => import('@components/PagePanel'),
        AButton,
        RiskCategorySelect: () => import('@weights/GbCloud/RiskCategorySelect'),
        GradeFactorBasicType: () => import('@weights/GradeModelInfo/GradeFactorTabPanel/GradeFactorBasicType'),
        GradeFactorSectionType: () => import('@weights/GradeModelInfo/GradeFactorTabPanel/GradeFactorSectionType'),
        SetComputedFormulaModal: () => import('@weights/SetComputedFormulaModal'),
        RiskObjSelect: () => import('@weights/GbCloud/RiskObjSelect')
    },
    mixins: [InitForm],
    data() {
        return {
            accMul,
            accDiv,
            validateWeight,
            GRADE_MODEL_GRADE_FACTOR_BASIC_TYPE,
            GRADE_MODEL_GRADE_FACTOR_SECTION_TYPE,
            labelCol: {
                span: 3
            },
            wrapperCol: {
                span: 8
            },
            GRADE_FACTOR_TYPE_ENUM_LIST2, // 基本类型 1 // 数值区间类型 2
            form: {
                scoringModelId: '', // 评分模型id
                masterDataId: '',
                formCategoryArr: [],
                categoryId: '',
                isFormula: false,
                riskFactorDefinitionId: undefined,
                source: '',
                scoringFactorType: GRADE_MODEL_GRADE_FACTOR_BASIC_TYPE, // 评分因子类型
                // scoringFactorType: '',// 评分因子类型
                weight: 0
            },
            loading: false,
            submitLoading: false,
            masterOption: null, // 风险对象选择的item,
            categoryOption: null, // 风险分类选择的item,
            dataList: [],
            limit: DEFAULT_PAGES_SIZE,
            total: 0,
            current: 1,
            // factorDataList: [],// 因子数据
            targetFactorDataList: [], // 因子数据备份 用来过滤dataType
            attributesData: [],
            modelShowCom: '',
            childrenRef: '',
            rules: {
                masterDataId: [
                    {
                        required: true,
                        message: '请选择风险对象',
                        trigger: 'blur'
                    }
                ],
                formCategoryArr: [
                    {
                        required: true,
                        message: '请选择风险分类',
                        trigger: 'blur'
                    }
                ],
                riskFactorDefinitionId: [
                    {
                        required: true,
                        message: '请选择评分因子',
                        trigger: 'blur'
                    }
                ],
                // weight: [
                //   {required: true, message: "请输入权重", trigger: "blur"}
                // ],
                isFormula: [
                    {
                        required: true,
                        message: '是否作为计算公式',
                        trigger: 'blur'
                    }
                ],
                scoringFactorType: [
                    {
                        required: true,
                        message: '请选择评分因子类型',
                        trigger: 'blur'
                    }
                ]
            },
            visible: false,
            factorDataItem: {}, // 选择的因子对应factorDataList的item->用来获取属性
            factorRecordInfo: {}, // 因子详情页 修改使用
            selectedFormulaObj: null// 设置的公式
        }
    },
    computed: {
        // 模型id
        queryId() {
            return this.$route.query.queryId
        },
        // 根据'评分计算方式'选择权重时显示权重配置；否则不显示；
        isWeight() {
            return !!+this.$route.query.isWeight
        },
        // 因子id 作修改使用
        id() {
            return this.$route.query.id || ''
        },
        // 修改时部分表单不可修改
        isDisabled() {
            const { id } = this
            return !!id
        },
        params() {
            // eslint-disable-next-line no-unused-vars
            const { formCategoryArr, ...params } = this.form
            params.weight = accDiv(+params.weight)
            return params
        },
        factorDataList() {
            const { targetFactorDataList } = this
            const { scoringFactorType } = this.form
            const res = targetFactorDataList.filter(item => {
                const dataType = item.dataType
                // 基本类型 需要dataType是'DICT' 'DICT_CASCADE'
                if (scoringFactorType === GRADE_MODEL_GRADE_FACTOR_BASIC_TYPE) {
                    return dataType === RISK_FACTOR_DATA_TYPE_DICT_TYPE || dataType === RISK_FACTOR_DATA_TYPE_DICT_CASCADE_TYPE
                } else if (scoringFactorType === GRADE_MODEL_GRADE_FACTOR_SECTION_TYPE) {
                    return targetFactorDataList

                    // 数值区间类型 需要dataType是RISK_FACTOR_DATA_TYPE_DATA_SECTION_OBJ 里面的
                    // return RISK_FACTOR_DATA_TYPE_DATA_SECTION_OBJ[dataType]
                }
                // else {
                //   return targetFactorDataList
                // }
            })
            return res
        },
        serviceFn() {
            const { id } = this
            return id ? editGradeFactorService : addGradeFactorService
        },
        commitMsg() {
            const { id } = this
            return id ? ERROR_COMMIT_EDIT : ERROR_COMMIT_SUCCESS
        },
        // 公式的name
        comment() {
            const { selectedFormulaObj } = this
            return selectedFormulaObj && selectedFormulaObj.formulaDisplay
        }
    },
    watch: {
        masterOption(val) {
            this.form.source = val.source
        },
        'form.categoryId': {
            handler(val) {
                this.targetFactorDataList = []
                this.form.masterDataClassificationId = val
                if (val) {
                    this.getCanAddRiskFactor()
                }
            }
        },
        // 评分因子类型动态切换视图
        'form.scoringFactorType': {
            handler(val) {
                if (val === GRADE_MODEL_GRADE_FACTOR_BASIC_TYPE) {
                    this.modelShowCom = 'GradeFactorBasicType' // 基本类型
                    this.childrenRef = 'basicRef'
                } else if (val === GRADE_MODEL_GRADE_FACTOR_SECTION_TYPE) {
                    this.modelShowCom = 'GradeFactorSectionType' // 数值区间类型
                    this.childrenRef = 'sectionRef'
                }
            },
            immediate: true
        },
        // 选择了因子->去取属性值列表
        'form.riskFactorDefinitionId': {
            handler(val) {
                if (!val) return
                this.factorDataItem = {
                    ...this.factorDataList.find(item => item.id === val)
                }
            },
            immediate: true
        },
        factorDataList: {
            handler(val) {
                const { id, form } = this
                if (id && form.riskFactorDefinitionId) { // 回显修改的属性值信息
                    this.factorDataItem = val.find(item => item.id === form.riskFactorDefinitionId)
                }
            }
        },
        // 修改 获取详情数据
        id: {
            handler(val) {
                if (!val) return
                this.form.id = val
                this.getFactorInfo()
            },
            immediate: true
        },
        factorRecordInfo: {
            handler(val) {
                const { factorRecordInfo, form } = this
                this.initForm(factorRecordInfo, form)
                this.form.categoryId = factorRecordInfo.masterDataClassificationId
                this.form.weight = accMul(+factorRecordInfo.weight)
                const { masterDataId, source } = val
                this.masterOption = {
                    masterDataId,
                    source
                }
                if (factorRecordInfo.isFormula) {
                    const { formulaDescription, formulaDisplay } = factorRecordInfo
                    this.selectedFormulaObj = {
                        formulaDescription,
                        formulaDisplay
                    }
                }
            }
        }
    },
    created() {
        this.form.scoringModelId = this.queryId.toString()
    },
    methods: {
        handleLoading(val) {
            this.loading = val
        },
        // 选择的风险对象item
        handleOption(option) {
            this.masterOption = option
            this.form.categoryId = '' // 点击后再清空form关联数据
        },
        handleFactorTypeChange() {
            // 评分因子类型不同 选择的风险因子也不同
            this.form.riskFactorDefinitionId = ''
        },
        // 点击设置计算公式
        handleSetFormula() {
            this.visible = true
        },
        handleModalCancel() {
            this.visible = false
        },
        // 设置公式成功回调
        handleSuccess(res) {
            this.selectedFormulaObj = {
                ...res
            }
            this.visible = false
        },
        handleCategoryOption(option) {
            this.categoryOption = option
        },
        handleCategoryChange(val) {
            this.form.categoryId = val
            this.form.riskFactorDefinitionId = ''
        },
        handleFactorIdChange(val) {
            if (!val) return
            this.factorDataItem = this.factorDataList.find(item => item.id === val)
        },
        handleCancel() {
            this.$router.back()
        },
        handleSubmit() {
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    const { childrenRef } = this
                    // 子组件的数组
                    const resObj = this.$refs[childrenRef].handleFormValidate()
                    resObj && this.commitService(resObj)
                }
            })
        },
        async commitService(resObj) {
            try {
                this.submitLoading = true
                const { params, commitMsg, serviceFn, id, selectedFormulaObj, childrenRef } = this
                let paramsObj = {
                    ...params,
                    ...resObj
                }
                // 计算公式判断
                if (params.isFormula) {
                    if (!selectedFormulaObj) {
                        this.$message.error('请设置计算公式')
                        return
                    }
                    Object.assign(paramsObj, selectedFormulaObj)
                }
                await serviceFn(paramsObj)
                this.$refs.ruleForm.resetFields()
                this.$refs[childrenRef].resetFields()
                this.selectedFormulaObj = null
                this.factorDataItem = {}
                this.$message.success(commitMsg)
                if (id) {
                    this.$router.back()
                }
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.submitLoading = false
            }
        },
        /**
         * 获取风险因子
         * */
        async getCanAddRiskFactor() {
            try {
                const { params, id } = this
                const res = await getCanAddRiskGradeFactorService(params)

                this.targetFactorDataList = id ? res : res.filter(item => item.isUsed === false)
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            }
        },
        /**
         * 获取属性值
         * */
        async getAttributes() {
            const { factorDataItem } = this
            if (!factorDataItem) return
            const { dataType, dictionaryCategoryId, source } = factorDataItem
            // 指定dataType 才有属性值
            if (dataType !== RISK_FACTOR_DATA_TYPE_DICT_TYPE && dataType !== RISK_FACTOR_DATA_TYPE_DICT_CASCADE_TYPE) return
            try {
                this.attributesData = await getAttributesService({
                    dataType,
                    dictionaryCategoryId,
                    source
                })
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            }
        },
        // 因子详情数据
        async getFactorInfo() {
            try {
                this.loading = true
                const { id } = this
                this.factorRecordInfo = await getGradeFactorInfoService(id)
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang='scss'>
.add-edit-grade-factor-page {
    .ant-form-item-label {
        min-width: 130px;
    }

    &__weight-input {
        width: 150px !important;
        margin-right: 8px
    }

    &__set-formula-text {
        float: right;
        color: #2998ff;
        font-size: 14px;
        cursor: pointer;
    }
}
</style>
