<template>
    <custom-modal
        :title="title"
        :visible="visible"
        :mask-closable="false"
        :width="650"
        @close="handleCancel">
        <template slot="customFooter">
            <a-button key="reset" @click="handleCancel">
                取消
            </a-button>
            <a-button key="submit" type="primary" :loading="loading" @click="handleOk">
                提交
            </a-button>
        </template>
        <form-panel
            ref="formPanel"
            :info="info"
            :mode="mode"
            @loading="handleLoading"
            @success="handleSuccess" />
    </custom-modal>
</template>

<script>
import { Button as AButton } from 'ant-design-vue'
import CustomModal from '@components/CustomModal'
import FormPanel from './FormPanel'

export default {
    name: 'AddOrEditCategoryModal',
    components: {
        AButton,
        CustomModal,
        FormPanel
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        mode: {
            type: String,
            default: ''
        },
        info: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            loading: false
        }
    },
    computed: {
        title() {
            return this.mode === 'create' ? '添加数据核验模型' : '修改数据核验模型'
        }
    },
    methods: {
        handleCancel() {
            this.$emit('cancel')
        },
        handleOk() {
            this.$refs.formPanel.handleSubmit()
        },
        handleLoading(val) {
            this.loading = val
        },
        handleSuccess() {
            this.loading = false
            this.$emit('success')
        }
    }
}
</script>

<style lang='scss'>

</style>
