<template>
    <a-form-model
        ref="ruleForm"
        class="add-edit-category-form-panel"
        layout="horizontal"
        :label-col="{ span: 6}"
        :wrapper-col="{ span: 16 }"
        :model="form"
        :rules="rules">
        <a-form-model-item label="评分模型名称" prop="modelExamplesName">
            <a-input
                v-model.trim="form.modelExamplesName"
                :max-length="INPUT_MAX_LENGTH"
                placeholder="请输入评分模型名称" />
        </a-form-model-item>
        <a-form-model-item label="模型描述" prop="description">
            <a-input
                v-model.trim="form.description"
                type="textarea"
                :rows="3"
                :max-length="INPUT_AREA_MAX_LENGTH"
                placeholder="请输入模型描述" />
        </a-form-model-item>
        <a-form-model-item label="业务类型" prop="riskBusinessType">
            <risk-business-type
                v-model="form.riskBusinessType" />
        </a-form-model-item>

        <a-form-model-item label="产品分类" prop="riskCategoryId">
            <product-category-cascader v-model="form.riskCategoryId" />
        </a-form-model-item>
        <a-form-model-item label="评分卡分类" prop="scoringModelType">
            <score-card-select v-model="form.scoringModelType" />
        </a-form-model-item>
        <a-form-model-item label="评分计算方式" prop="isWeight">
            <score-computed-select v-model="form.isWeight" />
        </a-form-model-item>
        <a-form-model-item
            label="评分卡基础分"
            prop="basicScoring"
            :rules="[{
                required: true,
                message: '请输入',
                trigger: 'blur',
            },{validator:validatePositiveNumber}]">
            <a-input
                v-model="form.basicScoring"
                :min="0"
                placeholder="请输入"
            />
        </a-form-model-item>
    </a-form-model>
</template>

<script>
import {
    FormModel as AFormModel, Input as AInput
} from 'ant-design-vue'
import RiskBusinessType from '@weights/RiskBusinessType'
import ProductCategoryCascader from '@weights/ProductCategoryCascader'


import { INPUT_MAX_LENGTH, INPUT_AREA_MAX_LENGTH } from '@constant/Const'
import { ERROR_COMMIT_EDIT, ERROR_COMMIT_SUCCESS } from '@constant/error'

import addGradeModelService from '@service/ruleEngine/GradeModel/addGradeModelService'
import editGradeModelService from '@service/ruleEngine/GradeModel/editGradeModelService'
import InitForm from '@mixins/initForm'
import { validatePositiveNumber } from '@constant/validates'

export default {
    name: 'FormPanel',
    components: {
        RiskBusinessType,
        ProductCategoryCascader,
        AFormModel,
        AInput,
        AFormModelItem: AFormModel.Item,
        ScoreCardSelect: () => import('@components/select/ScoreCardSelect'),
        ScoreComputedSelect: () => import('@components/select/ScoreComputedSelect')
    },
    mixins: [InitForm],
    props: {
        mode: {
            type: String,
            default() {
                return ''
            }
        },
        record: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            validatePositiveNumber,
            INPUT_MAX_LENGTH,
            INPUT_AREA_MAX_LENGTH,
            form: {
                modelExamplesName: '',
                riskBusinessType: '',
                description: '',
                riskCategoryId: '',
                scoringModelType: undefined,
                id: '',
                basicScoring: 0,
                isWeight: 0
            },
            rules: {
                modelExamplesName: [
                    {
                        required: true,
                        trigger: 'blur',
                        message: '请输入'
                    }
                ],
                riskBusinessType: [
                    {
                        required: true,
                        trigger: 'blur',
                        message: '请选择'
                    }
                ],
                riskCategoryId: [
                    {
                        required: true,
                        trigger: 'blur',
                        message: '请选择'
                    }
                ],
                scoringModelType: [
                    {
                        required: true,
                        trigger: 'blur',
                        message: '请选择'
                    }
                ],
                isWeight: [
                    {
                        required: true,
                        trigger: 'blur',
                        message: '请选择'
                    }
                ]
            }
        }
    },
    computed: {
        isAdd() {
            return this.mode === 'create'
        },
        serviceFn() {
            const { isAdd } = this
            return isAdd ? addGradeModelService : editGradeModelService
        },
        commitMsg() {
            const { isAdd } = this
            return isAdd ? ERROR_COMMIT_SUCCESS : ERROR_COMMIT_EDIT
        }
    },
    watch: {
        record: {
            handler() {
                let { record, form, isAdd } = this
                if (isAdd) return
                this.initForm(record, form)
            },
            immediate: true
        }
    },
    methods: {
        handleSubmit() {
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    this.submitService()
                }
            })
        },
        async submitService() {
            try {
                const { serviceFn, commitMsg, form } = this
                this.$emit('loading', true)
                await serviceFn(form)
                this.$message.success(commitMsg)
                this.$emit('success')
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.$emit('loading', false)
            }
        }
    }
}
</script>

<style lang='scss'>

</style>
