<template>
    <search-panel :model="form"
                  layout="vertical"
                  class="base-rule-search-panel"
                  @search="handleSearch"
                  @refresh="handleRefresh">
        <form-col :span="6" label="规则名称" prop="query">
            <template slot="formElements">
                <a-input v-model.trim="form.query" placeholder="请输入规则名称" />
            </template>
        </form-col>
    </search-panel>
</template>

<script>
import {
    Input as AInput
} from 'ant-design-vue'
import SearchPanel from '@components/SearchPanel'
import FormCol from '@components/FormCol'

import { filterParams } from '@utils/search.js'

export default {
    name: 'BRSearchPanel',
    components: {
        AInput,
        SearchPanel,
        FormCol
    },
    data() {
        return {
            form: {
                query: ''
            }
        }
    },
    methods: {
        /**
         * 响应搜索事件
         */
        handleSearch() {
            this.$emit('search', filterParams(this.form))
        },
        /**
         * 响应刷新事件
         */
        handleRefresh() {
            this.$emit('refresh', filterParams(this.form))
        }
    }
}
</script>

<style lang='scss'>
.base-rule-search-panel {
    .search-panel__inner {
        min-width: 600px;
    }
}
</style>
