<!-- 作为限制显示面板 -->
<template>
    <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol">
        <a-form-model-item label="选择主字段" prop="formMasterCategoryId">
            <a-select
                v-model="form.formMasterCategoryId"
                show-search
                placeholder="请选择"
                option-filter-prop="children">
                <a-select-option
                    v-for="item in masterDataList"
                    :key="item.factorCode"
                    :filter-option="false"
                    :value="item.factorCode"
                    :label="item.factorName">
                    {{ item.factorName }}
                </a-select-option>
            </a-select>
        </a-form-model-item>

        <a-form-model-item
            label="主字段属性"
            prop="categoryId"
            :label-col="{span:4,offset: 4}"
            :wrapper-col="{ span: 18,offset: 4}">
            <div style="overflow-x: scroll">
                <div style="min-width: 1100px">
                    <table>
                        <thead style="background: #fafafa">
                            <tr>
                                <th
                                    v-for="item in tabKesData"
                                    :key="item.name"
                                    class="ant-table-align-center">
                                    <div   :style="{width:item.width+'px',textAlign:item.align}">
                                        {{ item.name }}
                                    </div>
                                </th>
                            </tr>
                        </thead>
                    </table>
                    <a-spin v-if="loading" size="large" style="display: block; margin: 20px auto" />
                    <template v-else>
                        <master-field-attribute
                            v-for="(item,index) in attributesData"
                            :key="item.id"
                            :ref="`masterFieldRef${index}`"
                            :index="index"
                            :record="item"
                            :record-master-attribute="recordMasterAttribute" />
                    </template>
                </div>
            </div>
        </a-form-model-item>
    </a-form-model>
</template>

<script>
import {
    FormModel as AFormModel, Select as ASelect, Spin as ASpin
} from 'ant-design-vue'
import MasterFieldAttribute from '@weights/DataCheckInfo/MasterFieldAttribute'

import getAttributesService from '@service/gbCloud/getAttributesService'
import getRiskFactorAllListService from '@service/ruleEngine/riskFactor/getRiskFactorAllListService'

import { RISK_FACTOR_DATA_TYPE_DICT_TYPE, RISK_FACTOR_DATA_TYPE_DICT_CASCADE_TYPE } from '@constant/enum'

export default {
    name: 'IsLimitShowPanel',
    components: {
        AFormModel,
        AFormModelItem: AFormModel.Item,
        MasterFieldAttribute,
        ASelect,
        ASelectOption: ASelect.Option,
        ASpin
    },
    data() {
        return {
            RISK_FACTOR_DATA_TYPE_DICT_TYPE,
            RISK_FACTOR_DATA_TYPE_DICT_CASCADE_TYPE,
            labelCol: {
                span: 4,
                offset: 4
            },
            wrapperCol: {
                span: 8
            },
            attributesData: [], // 属性值
            recordMasterAttribute: {}, // 选择的因子对应的主数据item
            masterDataList: [], // 主字段列表
            loading: false,
            tabKesData: [
                {
                    name: '序号',
                    width: 100,
                    align: 'center'
                },
                {
                    name: '属性',
                    width: 100,
                    align: 'center'
                },
                {
                    name: '是否显示',
                    width: 150,
                    align: 'center'
                },
                {
                    name: '风险对象',
                    width: 200,
                    align: 'center'
                },
                {
                    name: '风险分类',
                    width: 200,
                    align: 'center'
                },
                {
                    name: '显示风险因子',
                    width: 300,
                    align: 'center'
                }
            ],
            form: {
                formMasterCategoryId: '', // 主字段选择的因子id
                masterCategoryId: '', // 取recordMasterAttribute 里的masterDataClassificationId
                masterFactorCode: '' // 取recordMasterAttribute 里的factorCode
            },
            rules: {
                formMasterCategoryId: [
                    {
                        required: true,
                        message: '请选择',
                        trigger: 'blur'
                    }
                ]
            }
        }
    },
    computed: {
        params() {
            const { form } = this
            let params = JSON.parse(JSON.stringify(form))
            delete params.formCategoryArr
            return params
        }
    },
    watch: {
        'form.formMasterCategoryId': {
            handler(val) {
                if (!val) return
                let record = this.masterDataList.find(item => item.factorCode === val)
                this.recordMasterAttribute = record
                const { masterDataClassificationId, factorCode } = record
                this.form.masterCategoryId = masterDataClassificationId
                this.form.masterFactorCode = factorCode
                this.getAttributes()
            }
        }
    },
    created() {
        this.getList()
    },
    methods: {
    // 清空所有的form
        resetFields() {
            this.$refs.ruleForm.resetFields()
            this.attributesData = [] // 清空属性值列表
            this.attributesData.map((item, index) => this.$refs[`masterFieldRef${index}`][0].resetFields())
        },
        handleFormValidate() {
            let res = null
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    const { attributesData } = this
                    // 收集每个属性值的form数据
                    const dataVerificationFactorDTOs = attributesData.map((item, index) => this.$refs[`masterFieldRef${index}`][0].handleFormValidate())
                    if (dataVerificationFactorDTOs.includes(null)) {
                        return
                    }
                    let resArr = []
                    // 判断选择是否显示
                    dataVerificationFactorDTOs.forEach(item => {
                        if (item !== '') {
                            resArr.push(item)
                        }
                    })
                    const { params } = this
                    // 必选有应该选择是
                    const find = resArr.find(item => item !== '')
                    if (!find) {
                        this.$message.warning('主字段属性配置错误')
                        return
                    }
                    res = {
                        ...params,
                        dataVerificationFactorDTOs: resArr
                    }
                }
            })
            return res
        },
        // 获取属性值
        async getAttributes() {
            const { recordMasterAttribute } = this
            const { dataType, dictionaryCategoryId, source } = recordMasterAttribute
            try {
                this.loading = true
                this.attributesData = await getAttributesService({
                    dataType,
                    dictionaryCategoryId,
                    source
                })
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            } finally {
                this.loading = false
            }
        },
        // 获取主数据 过滤主数据下的dataType只有是'DICT' 'DICT_CASCADE'才能获取属性值
        async getList() {
            try {
                const result = await getRiskFactorAllListService()
                // 过滤可用的主数据
                this.masterDataList = result.filter(item => this.filterDataFn(item))
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            }
        },
        filterDataFn(item) {
            const { dataType } = item
            return dataType === RISK_FACTOR_DATA_TYPE_DICT_TYPE || dataType === RISK_FACTOR_DATA_TYPE_DICT_CASCADE_TYPE
        }
    }
}
</script>

<style lang='scss' scoped>
tr {
  th {
    min-width: 100px;
    padding: 0 10px;
  }
}
</style>
