<template>
    <list-page-layout>
        <search-panel
            slot="header"
            @refresh="handleSearch"
            @search="handleSearch"
        />
        <container-panel>
            <template slot="title"> 评分模型列表 </template>
            <a-button
                slot="button"
                v-auth="RULE_ENGINE_ADD_GRADE_MODEL_BUTTON"
                type="primary"
                @click="handleAdd"
            >
                添加评分模型
            </a-button>
            <template slot="content" slot-scope="">
                <a-table
                    :columns="columns"
                    :data-source="dataList"
                    :pagination="pagination"
                    :loading="loading"
                    row-key="id"
                    :scroll="scrollConfig"
                    @change="handleTableChange"
                >
                    <template slot="state" slot-scope="text, action">
                        <a-switch
                            :disabled="
                                action.auditState !== DATA_CHECK_MODEL_AUDIT_STATE_PASS
                            "
                            :checked="Boolean(action.state)"
                            @change="(value) => handleSwitch(value, action)"
                        />
                    </template>

                    <template slot="action" slot-scope="text, action">
                        <a-button
                            v-auth="RULE_ENGINE_GRADE_MODEL_INFO_MENU_CODE"
                            type="link"
                            @click="handleLook(action)"
                        >
                            查看
                        </a-button>
                        <a-button
                            v-auth="RULE_ENGINE_COPY_GRADE_MODEL_MODEL_BUTTON"
                            type="link"
                            @click="handleCopy(action)"
                        >
                            复制
                        </a-button>
                        <a-button
                            v-if="showDelete(action)"
                            v-auth="RULE_ENGINE_LOGIC_DELETE_GRADE_MODEL_BUTTON"
                            :disabled="!!action.state"
                            type="link"
                            @click="handleDelete(action.id)"
                        >
                            删除
                        </a-button>
                    </template>
                </a-table>
            </template>
        </container-panel>
        <add-or-edit-grade-modal
            :mode="mode"
            :visible="visible"
            :record="record"
            @cancel="handleCancel"
            @success="handleSuccess"
        />
        <confirm-delete
            v-bind="deleteProp"
            @close="deleteProp.visible = false"
            @ok="sendDeleteService"
        />
    </list-page-layout>
</template>

<script>
import {
    Button as AButton,
    Table as ATable,
    Switch as ASwitch
} from 'ant-design-vue'
import {
    RULE_ENGINE_ADD_GRADE_MODEL_BUTTON,
    RULE_ENGINE_COPY_GRADE_MODEL_MODEL_BUTTON,
    RULE_ENGINE_LOGIC_DELETE_GRADE_MODEL_BUTTON
} from '@constant/authEnum/ruleEngine'
import { RULE_ENGINE_GRADE_MODEL_INFO_MENU_CODE } from '@constant/menu_code'
import AddOrEditGradeModal from './AddOrEditGradeModal'
import ContainerPanel from '@components/ContainerPanel'
import ListPageLayout from '@layout/ListPageLayout'
import SearchPanel from './SearchPanel'

import {
    AUDIT_STATE_MAP,
    DATA_CHECK_MODEL_AUDIT_STATE_PASS,
    BUSINESS_TYPE_MAP,
    SCORE_CARD_MAP,
    RULE_ENGINE_DELETE_BUTTON
} from '@constant/enum'

import getGradeModelListService from '@service/ruleEngine/GradeModel/getGradeModelListService'
import openOrCloseGradeModelService from '@service/ruleEngine/GradeModel/openOrCloseGradeModelService'
import copyGradeModelService from '@service/ruleEngine/GradeModel/copyGradeModelService'
import logicDeleteModelService from '@service/ruleEngine/GradeModel/logicDeleteModelService'

import {
    ERROR_COMMIT_CLOSE,
    ERROR_COMMIT_DELETE,
    ERROR_COMMIT_OPEN,
    ERROR_COPY_SUCCESS
} from '@constant/error'
import { actionModelConfirm } from '@utils/tip'
import initTable from '@mixins/initTable'
import deleteModel from '@mixins/deleteModel'
import { open } from '@utils'
const columns = [
    {
        title: '序号',
        dataIndex: 'index',
        align: 'left',
        width: 100,
        customRender: (v, r, idx) => ++idx,
        key: 'index',
        fixed: 'left'
    },
    {
        title: '评分模型名称',
        dataIndex: 'modelExamplesName',
        align: 'left',
        width: 260,
        key: 'modelExamplesName'
    },
    {
        title: '业务类型',
        dataIndex: 'riskBusinessType',
        align: 'left',
        width: 200,
        key: 'riskBusinessType',
        customRender: v => BUSINESS_TYPE_MAP[v]
    },
    {
        title: '产品分类',
        dataIndex: 'riskCategoryName',
        align: 'left',
        width: 300,
        key: 'riskCategoryName'
    },
    {
        title: '评分卡分类',
        dataIndex: 'scoringModelType',
        align: 'left',
        width: 200,
        key: 'scoringModelType',
        customRender: v => SCORE_CARD_MAP[v]
    },
    {
        title: '审核状态',
        dataIndex: 'auditState',
        align: 'left',
        key: 'auditState',
        width: 200,
        customRender: v => AUDIT_STATE_MAP[v]
    },
    {
        title: '是否启用',
        dataIndex: 'state',
        align: 'left',
        key: 'state',
        width: 200,
        scopedSlots: {
            customRender: 'state'
        }
    },
    {
        title: '更新时间',
        dataIndex: 'modifyDateTime',
        align: 'left',
        width: 200,
        key: 'modifyDateTime'
    },
    {
        title: '操作',
        key: 'action',
        align: 'left',
        width: 200,
        scopedSlots: {
            customRender: 'action'
        },
        fixed: 'right'
    }
]
export default {
    name: 'GradeModelList',
    components: {
        ListPageLayout,
        ContainerPanel,
        AddOrEditGradeModal,
        AButton,
        ATable,
        SearchPanel,
        ASwitch
    },
    mixins: [initTable, deleteModel],
    data() {
        return {
            RULE_ENGINE_ADD_GRADE_MODEL_BUTTON,
            RULE_ENGINE_COPY_GRADE_MODEL_MODEL_BUTTON,
            RULE_ENGINE_LOGIC_DELETE_GRADE_MODEL_BUTTON,
            RULE_ENGINE_GRADE_MODEL_INFO_MENU_CODE,
            DATA_CHECK_MODEL_AUDIT_STATE_PASS,
            columns,
            params: {},
            visible: false,
            mode: '',
            record: {}
        }
    },
    methods: {
        handleAdd() {
            this.mode = 'create'
            this.visible = true
        },
        // 添加成功
        handleSuccess() {
            this.visible = false
            this.getList()
        },
        handleCancel() {
            this.visible = false
        },
        handleLook({ id }) {
            open.call(this, 'GradeModelInfo', {
                id
            })
        },
        async handleCopy({ id }) {
            await actionModelConfirm.call(this)
            this.copyService(id)
        },
        async copyService(id) {
            try {
                await copyGradeModelService({
                    id
                })
                this.$message.success(ERROR_COPY_SUCCESS)
                this.getList()
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            }
        },
        handleSearch(params) {
            this.params = params
            this.current = 1
            this.getList()
        },
        async sendDeleteService(params) {
            try {
                await logicDeleteModelService({
                    ...params,
                    id: this.deleteProp.id
                })
                this.getList()
                this.$message.success(ERROR_COMMIT_DELETE)
                this.deleteProp.visible = false
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            }
        },
        async handleSwitch(value, record) {
            try {
                let param = {
                    enable: +value,
                    id: record.id
                }
                await openOrCloseGradeModelService(param)
                let message = value ? ERROR_COMMIT_OPEN : ERROR_COMMIT_CLOSE
                this.$message.success(message)
                this.getList()
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            }
        },
        async getList() {
            try {
                this.loading = true
                const { current, limit, params } = this
                const param = {
                    current,
                    size: limit,
                    logicDelete: 0,
                    ...params
                }
                const { data, buttonPermission } =
                    await getGradeModelListService(param)
                this.hasDeletePermission =
                    buttonPermission[RULE_ENGINE_DELETE_BUTTON]
                this.dataList = data.records
                this.current = data.current
                this.total = data.total
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang='scss'>
</style>
