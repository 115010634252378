<template>
    <list-page-layout>
        <search-panel slot="header" @search="handleSearch" @refresh="handleSearch" />
        <container-panel>
            <template slot="title">
                数据核验模型列表
            </template>
            <a-button slot="button"
                      v-auth="RULE_ENGINE_ADD_DATA_CHECK_MODEL_BUTTON"
                      type="primary"
                      @click="handleAddData">
                添加数据核验模型
            </a-button>
            <template slot="content" slot-scope="">
                <a-table :columns="columns"
                         :data-source="dataList"
                         :pagination="pagination"
                         :loading="loading"
                         :scroll="scrollConfig"
                         row-key="id"
                         @change="handleTableChange">
                    <template slot="state" slot-scope="text,record">
                        <a-switch
                            :disabled="record.auditState!==DATA_CHECK_MODEL_AUDIT_STATE_PASS"
                            :checked="Boolean(record.state)"
                            @change="value => handleSwitch(value, record)" />
                    </template>

                    <template slot="action" slot-scope="action">
                        <a-button
                            v-auth="RULE_ENGINE_DATA_CHECK_INFO_MENU_CODE"
                            type="link"
                            @click="handleLook(action)">
                            查看
                        </a-button>
                        <a-button
                            v-auth="RULE_ENGINE_COPY_DATA_CHECK_MODEL_BUTTON"
                            type="link"
                            @click="handleCopy(action)">
                            复制
                        </a-button>
                        <a-button
                            v-if="showDelete(action)"
                            v-auth="RULE_ENGINE_LOGIC_DELETE_DATA_CHECK_MODEL_BUTTON"
                            :disabled="!!action.state"
                            type="link"
                            @click="handleDelete(action.id)">
                            删除
                        </a-button>
                    </template>
                </a-table>
            </template>
        </container-panel>

        <add-or-edit-data-check-modal
            mode="create"
            :visible="visible"
            @success="handleSuccess"
            @cancel="handleCancel"
        />
        <confirm-delete
            v-bind="deleteProp"
            @close="deleteProp.visible=false"
            @ok="sendDeleteService" />
    </list-page-layout>
</template>

<script>
import { Button as AButton, Table as ATable, Switch as ASwitch } from 'ant-design-vue'
import ListPageLayout from '@layout/ListPageLayout'
import SearchPanel from './SearchPanel'
import ContainerPanel from '@components/ContainerPanel'
import AddOrEditDataCheckModal from './AddOrEditDataCheckModal'

import {
    RULE_ENGINE_ADD_DATA_CHECK_MODEL_BUTTON,
    RULE_ENGINE_COPY_DATA_CHECK_MODEL_BUTTON,
    RULE_ENGINE_LOGIC_DELETE_DATA_CHECK_MODEL_BUTTON
} from '@constant/authEnum/ruleEngine'
import { RULE_ENGINE_DATA_CHECK_INFO_MENU_CODE } from '@constant/menu_code'
import {
    BUSINESS_TYPE_MAP,
    AUDIT_STATE_MAP,
    DATA_CHECK_MODEL_AUDIT_STATE_PASS,
    RULE_ENGINE_DELETE_BUTTON
} from '@constant/enum'

import { filterParams } from '@utils/search'

import getDataCheckModelListService from '@service/ruleEngine/DataCheckSetting/getDataCheckModelListService'
import openOrCloseSwitchService from '@service/ruleEngine/DataCheckSetting/openOrCloseSwitchService'
import copyDataCheckModelService from '@service/ruleEngine/DataCheckSetting/copyDataCheckModelService'
import logicDeleteModelService from '@service/ruleEngine/DataCheckSetting/logicDeleteModelService'

import { ERROR_COMMIT_CLOSE, ERROR_COMMIT_DELETE, ERROR_COMMIT_OPEN, ERROR_COPY_SUCCESS } from '@constant/error'
import { actionModelConfirm } from '@utils/tip'
import initTable from '@mixins/initTable'
import deleteModel from '@mixins/deleteModel'
import { open } from '@utils'

const columns = [
    {
        title: '序号',
        dataIndex: 'index',
        align: 'left',
        key: 'index',
        width: 100,
        customRender: (v, r, idx) => ++idx,
        fixed: 'left'
    },
    {
        title: '数据核验模型名称',
        dataIndex: 'modelExamplesName',
        width: 200,
        align: 'left',
        key: 'modelExamplesName'
    },
    {
        title: '业务类型',
        dataIndex: 'riskBusinessType',
        align: 'left',
        width: 160,
        key: 'riskBusinessType',
        customRender: v => BUSINESS_TYPE_MAP[v]
    },
    {
        title: '产品分类',
        dataIndex: 'riskCategoryName',
        align: 'left',
        width: 340,
        key: 'riskCategoryName'
    },
    {
        title: '审核状态',
        dataIndex: 'auditState',
        align: 'left',
        key: 'auditState',
        width: 150,
        customRender: v => AUDIT_STATE_MAP[v]
    },
    {
        title: '是否启用',
        dataIndex: 'state',
        align: 'left',
        key: 'state',
        width: 120,
        scopedSlots: {
            customRender: 'state'
        }
    },
    {
        title: '更新时间',
        dataIndex: 'createDateTime',
        align: 'left',
        width: 200,
        key: 'createDateTime'
    },
    {
        title: '操作',
        key: 'action',
        align: 'left',
        width: 200,
        scopedSlots: {
            customRender: 'action'
        },
        fixed: 'right'
    }
]
export default {
    name: 'DataCheckList',
    components: {
        SearchPanel,
        AddOrEditDataCheckModal,
        ListPageLayout,
        ContainerPanel,
        ATable,
        AButton,
        ASwitch
    },
    mixins: [initTable, deleteModel],
    data() {
        return {
            RULE_ENGINE_ADD_DATA_CHECK_MODEL_BUTTON,
            RULE_ENGINE_COPY_DATA_CHECK_MODEL_BUTTON,
            RULE_ENGINE_LOGIC_DELETE_DATA_CHECK_MODEL_BUTTON,
            RULE_ENGINE_DATA_CHECK_INFO_MENU_CODE,
            DATA_CHECK_MODEL_AUDIT_STATE_PASS,
            columns,
            params: {},
            visible: false
        }
    },
    methods: {
        handleSearch(params) {
            this.params = params
            this.getList()
        },
        handleCancel() {
            this.visible = false
        },
        handleSuccess() {
            this.visible = false
            this.getList()
        },
        handleAddData() {
            this.visible = true
        },
        handleLook({ id }) {
            open.call(this, 'DataCheckInfo', {
                id
            })
        },
        async sendDeleteService(params) {
            try {
                await logicDeleteModelService({
                    ...params,
                    id: this.deleteProp.id
                })
                this.getList()
                this.$message.success(ERROR_COMMIT_DELETE)
                this.deleteProp.visible = false
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            }
        },
        async handleCopy({ id }) {
            await actionModelConfirm.call(this)
            this.copyService(id)
        },
        async copyService(id) {
            try {
                await copyDataCheckModelService({
                    id
                })
                this.getList()
                this.$message.success(ERROR_COPY_SUCCESS)
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            }
        },
        async handleSwitch(value, record) {
            try {
                const params = {
                    enable: +value,
                    id: record.id
                }
                await openOrCloseSwitchService(params)
                let message = value ? ERROR_COMMIT_OPEN : ERROR_COMMIT_CLOSE
                this.$message.success(message)
                this.getList()
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            }
        },
        async getList() {
            try {
                this.loading = true
                const { current, limit, params } = this
                const param = {
                    current,
                    size: limit,
                    logicDelete: 0,
                    ...params
                }
                const { data, buttonPermission } = await getDataCheckModelListService(filterParams(param))
                this.hasDeletePermission = buttonPermission[RULE_ENGINE_DELETE_BUTTON]
                this.dataList = data.records
                this.current = data.current
                this.total = data.total
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }

        }
    }
}
</script>

<style lang='scss'>

</style>
