<!-- 基础规则-数值区间类型 -->
<template>
    <a-form-model
        ref="ruleForm"
        class="grade-factor-section-type"
        :model="form">
        <div style="overflow-x: scroll">
            <table>
                <thead class="grade-factor-section-type__thead">
                    <tr>
                        <th
                            v-for="item in typeTab"
                            :key="item.name"
                            class="ant-table-align-center">
                            <div :style="{width:item.width+'px',textAlign:item.align,padding:'0 20px'}">
                                {{
                                    item.name
                                }}
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody class="grade-factor-basic-type">
                    <tr v-for="(item,index) in form.basicRuleDetailList" :key="item.$id">
                        <td>
                            <a-form-model-item style="width: 60px">{{ index + 1 }}</a-form-model-item>
                        </td>
                        <td>
                            <a-form-model-item style="width: 200px">{{ showAttributeText(item) }}</a-form-model-item>
                        </td>
                        <td>
                            <div class="grade-factor-section-type__score">
                                <a-form-model-item
                                    :prop="`basicRuleDetailList[${index}].minValueComparator`">
                                    <min-comparator
                                        v-model="item.minValueComparator"
                                        :disabled="isLook" />
                                </a-form-model-item>
                                <a-form-model-item
                                    :prop="`basicRuleDetailList[${index}].min`"
                                    :rules="negativeAttributeRules">
                                    <a-input-number
                                        v-model="item.min"
                                        :max="MAX_VALUE"
                                        :disabled="isLook"
                                        class="grade-factor-section-type__input-number"
                                        placeholder="输入数值" />
                                </a-form-model-item>
                                <a-form-model-item>~</a-form-model-item>
                                <a-form-model-item
                                    :prop="`basicRuleDetailList[${index}].maxValueComparator`">
                                    <max-comparator
                                        v-model="item.maxValueComparator"
                                        :disabled="isLook" />
                                </a-form-model-item>
                                <a-form-model-item
                                    :prop="`basicRuleDetailList[${index}].max`"
                                    :rules="negativeAttributeRules">
                                    <a-input-number
                                        v-model="item.max"
                                        :max="MAX_VALUE"
                                        :disabled="isLook"
                                        class="grade-factor-section-type__input-number"
                                        placeholder="输入数值" />
                                </a-form-model-item>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </a-form-model>
</template>

<script>
import {
    FormModel as AFormModel, InputNumber as AInputNumber
} from 'ant-design-vue'
import MinComparator from '@weights/Comparator/MinComparator'
import MaxComparator from '@weights/Comparator/MaxComparator'
import {
    GREATER_AND_LESS_THAN_TYPE_ENUM_LIST,
    GRADE_MODEL_GRADE_FACTOR_SECTION_TYPE,
    GRADE_MODEL_GRADE_FACTOR_SECTION_TYPE_MAX_VALUE,
    LESS_THAN_OR_EQUAL_TO_TYPE,
    GREATER_THAN_OR_EQUAL_TO_TYPE
} from '@constant/enum'
import { validateNegativeAttribute, validateAttribute } from '@constant/validates'
import enumOperate from '@mixins/enumOperate'

export default {
    name: 'SectionTypeView',
    components: {
        AInputNumber,
        AFormModel,
        AFormModelItem: AFormModel.Item,
        MinComparator,
        MaxComparator
    },
    mixins: [enumOperate],
    props: {
        ruleInfo: {
            type: Object,
            default() {
                return {}
            }
        },
        attributesData: {
            type: Array,
            default() {
                return []
            }
        },
        ruleType: {
            type: Number
        },
        isLook: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            MAX_VALUE: GRADE_MODEL_GRADE_FACTOR_SECTION_TYPE_MAX_VALUE,
            form: {
                basicRuleDetailList: [
                    {
                        $id: 0
                    }
                ]
            },
            typeTab: [
                {
                    name: '序号',
                    width: 100,
                    align: 'left'
                }, {
                    name: '属性',
                    width: 200,
                    align: 'left'
                }, {
                    name: '数值',
                    width: 500,
                    align: 'left'
                }
            ],
            rules: {},
            mode: '',
            // 支持负数
            negativeAttributeRules: [
                {
                    validator: validateNegativeAttribute
                }
            ],
            attributeRules: [
                {
                    validator: validateAttribute
                }
            ]
        }
    },
    computed: {
        params() {
            const { form, ruleType } = this
            let params = JSON.parse(JSON.stringify(form))
            params.basicRuleDetailList = params.basicRuleDetailList.map(item => {
                // eslint-disable-next-line no-unused-vars
                const { $id, ...targetItem } = item
                // 添加新字段
                targetItem.ruleType = ruleType
                return targetItem
            })
            return params
        },
        id() {
            return this.$route.query.id || ''
        }
    },
    watch: {
        ruleInfo: {
            handler(val) {
                if (val.ruleType === GRADE_MODEL_GRADE_FACTOR_SECTION_TYPE) {
                    const { basicRuleDetailList } = val
                    basicRuleDetailList.forEach(item => item.$id = item.id)
                    this.form.basicRuleDetailList = basicRuleDetailList
                    this.mode = 'edit'
                }
            },
            immediate: true
        }
    },
    methods: {
        showAttributeText(item) {
            let { maxValueComparator = '', minValueComparator = '', min = '', max = '' } = item
            const leftSymbol = this.getApiEnumLabelFromList(GREATER_AND_LESS_THAN_TYPE_ENUM_LIST, minValueComparator, 'text') || ''
            const rightSymbol = this.getApiEnumLabelFromList(GREATER_AND_LESS_THAN_TYPE_ENUM_LIST, maxValueComparator, 'text') || ''
            min = min !== null ? min : ''
            max = max !== null ? max : ''
            if (max === this.MAX_VALUE) {
                max = '最大值'
            }
            return `${leftSymbol}${min} ,${max}${rightSymbol}`
        },
        resetFields() {
            this.form.basicRuleDetailList = [
                {
                    $id: 0
                }
            ]
        },
        handleFormValidate() {
            let res = null
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    const info = this.form.basicRuleDetailList[0]
                    const { minValueComparator, min, maxValueComparator, max } = info
                    if (minValueComparator && min !== undefined) { // 左边有值

                        info.maxValueComparator ??= LESS_THAN_OR_EQUAL_TO_TYPE
                        info.max ??= this.MAX_VALUE

                        res = this.params
                    }
                    if (maxValueComparator && max !== undefined) { // 右边有值

                        info.minValueComparator ??= GREATER_THAN_OR_EQUAL_TO_TYPE
                        info.min ??= -this.MAX_VALUE

                        res = this.params
                    }

                    if (res === null) { // 不满足条件
                        this.$message.error('未进行数值配置')
                    }
                }
            })
            return res
        }
    }
}
</script>

<style lang='scss'>
@import '@assets/styles/varibles.scss';

.grade-factor-section-type {
    &__basic {
        width: 150px;
        text-align: center;
    }

    .ant-input,
 .ant-select {
        width: 100px;
    }

    td {
        text-align: left;
        padding: 0 20px;
    }


    &__input-number {
        width: 135px;
    }

    table {
        min-width: $min-width;
    }

    &__thead {
        background-color: $custom-table-title-background-color;

        tr {
            line-height: $custom-table-thead-height
        }
    }

    &__score {
        display: flex;

        .ant-form-item {
            margin-right: 10px;
        }
    }
}
</style>
