<template>
    <search-panel :model="form"
                  layout="vertical"
                  class="enterprise-list-search-panel"
                  @search="handleSearch"
                  @refresh="handleRefresh">
        <form-col v-bind="colProps"
                  label="评分模型名称"
                  prop="query">
            <template slot="formElements">
                <a-input v-model.trim="form.query" placeholder="请输入评分模型名称" />
            </template>
        </form-col>
        <form-col v-bind="colProps" label="业务类型" prop="riskBusinessType">
            <risk-business-type
                v-model="form.riskBusinessType"
                slot="formElements" />
        </form-col>
        <form-col v-bind="colProps" label="产品分类" prop="riskCategoryId">
            <product-category-cascader v-model="form.riskCategoryId"
                                       slot="formElements" />
        </form-col>
        <form-col v-bind="colProps" label="评分卡分类" prop="scoringModelType">
            <score-card-select v-model="form.scoringModelType"
                               slot="formElements" />
        </form-col>
        <form-col  v-bind="colProps" label="是否启用" prop="state">
            <state-select
                v-model="form.state"
                slot="formElements" />
        </form-col>
    </search-panel>
</template>

<script>
import {
    Input as AInput
} from 'ant-design-vue'
import SearchPanel from '@components/SearchPanel'
import FormCol from '@components/FormCol'
import RiskBusinessType from '@weights/RiskBusinessType'
import ProductCategoryCascader from '@weights/ProductCategoryCascader'
import { filterParams } from '@utils/search'

export default {
    name: 'GMSearchPanel',
    components: {
        AInput,
        SearchPanel,
        FormCol,
        RiskBusinessType,
        ProductCategoryCascader,
        StateSelect: () => import('@components/select/StateSelect'),
        ScoreCardSelect: () => import('@components/select/ScoreCardSelect')
    },
    data() {
        return {
            colProps: {
                labelSpan: 6,
                span: 6
            },
            form: {
                query: '',
                riskBusinessType: '',
                riskCategoryId: ''
            },
            masterOption: null, // 风险对象选择的item,
            categoryOption: null // 风险分类选择的item
        }
    },
    computed: {},
    watch: {
        masterOption() {
            this.form.masterDataClassificationId = ''
        }
    },
    methods: {
        handleOption(option) {
            this.masterOption = option
        },
        handleCategoryOption(option) {
            this.categoryOption = option
        },
        /**
     * 响应搜索事件
     */
        handleSearch() {
            this.$emit('search', filterParams(this.form))
        },
        /**
     * 响应刷新事件
     */
        handleRefresh() {
            this.$emit('refresh', filterParams(this.form))
        }
    }
}
</script>

<style lang='scss'>

</style>
