<template>
    <page-layout>
        <page-panel>
            <a-form-model
                ref="ruleForm"
                :model="form"
                :rules="rules"
                :label-col="labelCol"
                :wrapper-col="wrapperCol">
                <a-form-model-item label="布局分类" prop="layoutId">
                    <layout-category
                        v-model="form.layoutId"
                        :max-page-size="true" />
                </a-form-model-item>
                <a-form-model-item label="是否作为限制显示" prop="nodeType">
                    <a-radio-group v-model="form.nodeType" name="radioGroup">
                        <a-radio :value="DATA_CHECK_MODEL_FACTOR_IS_LIMIT_SHOW_TYPE">是</a-radio>
                        <a-radio :value="DATA_CHECK_MODEL_FACTOR_NO_LIMIT_SHOW_TYPE">否</a-radio>
                    </a-radio-group>
                </a-form-model-item>
                <!-- 不作为限制显示的面板 -->
                <no-limit-show-panel
                    v-show="form.nodeType===DATA_CHECK_MODEL_FACTOR_NO_LIMIT_SHOW_TYPE"
                    ref="noLimitRef" />
                <!-- 作为限制显示的面板 -->
                <is-limit-show-panel
                    v-show="form.nodeType===DATA_CHECK_MODEL_FACTOR_IS_LIMIT_SHOW_TYPE"
                    ref="isLimitRef" />
                <a-form-model-item :wrapper-col="{ span: 8, offset: 10 }">
                    <a-button type="default" @click="handleCancel">取消</a-button>
                    <a-button type="primary"
                              style="margin-left: 20px;"
                              :loading="loading"
                              @click="handleSubmit">
                        提交
                    </a-button>
                </a-form-model-item>
            </a-form-model>
        </page-panel>
    </page-layout>
</template>

<script>
import {
    FormModel as AFormModel,
    Radio as ARadio,
    Button as AButton
} from 'ant-design-vue'
import PagePanel from '@components/PagePanel'
import PageLayout from '@layout/PageLayout'
import LayoutCategory from '@weights/LayoutCategory'
import NoLimitShowPanel from './NoLimitShowPanel'// 不受限制面板组件
import IsLimitShowPanel from './IsLimitShowPanel'// 受限制面板组件

import addDataCheckRiskFactorService from '@service/ruleEngine/DataCheckSetting/addDataCheckRiskFactorService'
import { DEFAULT_PAGES_SIZE } from '@config'
import { ERROR_COMMIT_SUCCESS } from '@constant/error'
import {
    DATA_CHECK_MODEL_FACTOR_NO_LIMIT_SHOW_TYPE,
    DATA_CHECK_MODEL_FACTOR_IS_LIMIT_SHOW_TYPE
} from '@constant/enum'

export default {
    name: 'AddRiskFactor',
    components: {
        PageLayout,
        LayoutCategory,
        AFormModel,
        AFormModelItem: AFormModel.Item,
        PagePanel,
        ARadioGroup: ARadio.Group,
        ARadio,
        AButton,
        NoLimitShowPanel,
        IsLimitShowPanel
    },
    data() {
        return {
            DATA_CHECK_MODEL_FACTOR_NO_LIMIT_SHOW_TYPE,
            DATA_CHECK_MODEL_FACTOR_IS_LIMIT_SHOW_TYPE,
            labelCol: {
                span: 4,
                offset: 4
            },
            wrapperCol: {
                span: 8
            },
            form: {
                layoutId: '',
                nodeType: 1,
                dataVerificationModelId: ''
            },
            loading: false,
            limit: DEFAULT_PAGES_SIZE,
            total: 0,
            current: 1,
            rules: {
                layoutId: [
                    {
                        required: true,
                        message: '请选择布局分类',
                        trigger: 'blur'
                    }
                ],
                nodeType: [
                    {
                        required: true,
                        message: '请选择',
                        trigger: 'blur'
                    }
                ]
            },
            childrenRef: ''
        }
    },
    computed: {
        queryId() {
            return this.$route.query.queryId
        }
    },
    watch: {
        'form.nodeType': {
            handler(val) {
                if (val === DATA_CHECK_MODEL_FACTOR_NO_LIMIT_SHOW_TYPE) {
                    this.childrenRef = 'noLimitRef'
                } else if (val === DATA_CHECK_MODEL_FACTOR_IS_LIMIT_SHOW_TYPE) {
                    this.childrenRef = 'isLimitRef'
                }
            },
            immediate: true
        }
    },
    created() {
        this.form.dataVerificationModelId = this.queryId
    },
    methods: {
        handleCancel() {
            this.$router.back()
        },
        handleSubmit() {
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    const { childrenRef } = this
                    // resObj 子组件的form数据
                    const resObj = this.$refs[childrenRef].handleFormValidate()
                    resObj && this.addService(resObj)
                }
            })
        },
        async addService(resObj) {
            try {
                this.loading = true
                const { form, childrenRef } = this
                const params = {
                    ...form,
                    ...resObj
                }
                await addDataCheckRiskFactorService(params)
                this.$refs[childrenRef].resetFields()
                this.$refs.ruleForm.resetFields()
                this.$message.success(ERROR_COMMIT_SUCCESS)
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        }


    }
}
</script>

<style lang='scss'>

</style>
